import { useEffect, useState, useRef } from 'react'

const isInViewport = (element) => {
    const rect = element.getBoundingClientRect()
    return (
        rect.right >= 0
    )
}

const TickerTape = (props) => {

    const [items, setItems] = useState(props.children)
    const [offset, setOffset] = useState(0)
    const firstChild = useRef()

    let speed = props.speed || 1
    let gap = props.gap || 50

    const animationStep = () => {

        if ( !isInViewport(firstChild.current) ) {
            // Reset ticker offset
            setOffset(0 - speed)
            // Move first item to end of array
            setItems(prevState => {
                let arr = [...prevState]
                arr.push(arr.splice(0, 1)[0])
                return arr
            })
        } else {
            setOffset(prevState => prevState - speed)
        }

        window.requestAnimationFrame(animationStep)
    }

    // Start animation

    useEffect(() => {
        const animationFrame = window.requestAnimationFrame(animationStep)
        return () => cancelAnimationFrame(animationFrame)
    }, [])

    return (
        <div className="ticker-tape-wrapper">
            <div className="ticker-tape" style={{ transform: "translatex(" + offset + "px)" }}>
                { items.map((item, i) => {
                    return (
                        <div
                            key={i}
                            ref={ i === 0 ? firstChild : null }
                            className="ticker-tape__item"
                            style={{ paddingInline: (gap/2) + "px" }}
                            dangerouslySetInnerHTML={{__html: item}}
                        >
                        </div>
                    )
                }) }
            </div>
        </div>
    )
}

export default TickerTape