import { render } from '@wordpress/element';
import TickerTape from './components/TickerTape';

const tickerTapeDomElements = document.querySelectorAll('.ticker-tape-element')

if ( typeof tickerTapeDomElements != 'undefined' && tickerTapeDomElements.length > 0 ) {

    tickerTapeDomElements.forEach((element) => {
        // Fetch settings from shortcode
        const gapSetting = element.getAttribute('data-gap')
        const speedSetting = element.getAttribute('data-speed')
        // Convert element.children from object to array so it can be looped
        const childrenArray = [...element.children]
        // Convert Javascript dom elements to HTML
        let children = []
        childrenArray.forEach((child) => {
            children.push(child.outerHTML)
        })
        // Render the TickerTape component into the DOM
        render(
            <TickerTape gap={gapSetting} speed={speedSetting} children={children}></TickerTape>,
            element
        );
    })

}